import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="glitch-wrapper">
   <span class="glitch" data-glitch="Dataknobs">Dataknobs</span>
   <span class="glitch" id="dot">.</span>
  
</div>

    </div>
  );
}

export default App;
